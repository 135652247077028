.addattachement{
.form{ position: relative;}
.form input[type="file"]{
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity = 0);-ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding:0;
    left:0;
}

.add-photo-btn{
    position:absolute;
    overflow:hidden;
    cursor:pointer;
    text-align:center;
	left: 0px ;
   
}
.form input[type="text"]{
	left: 85px;
    position: relative; border:1px solid #E4E6EF; border-radius: 4px; height: 36px; background: white;

}

.form input[type="text"]:focus-visible{border:1px solid #E4E6EF!important; }
}