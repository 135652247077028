.fc {

    .fc-toolbar{
        @include media-breakpoint-down(sm) {
            display:block; text-align: center;
            }
    }

    .fc-toolbar-chunk{
        .fc-new-button:hover{
            background-color:$dark-inverse!important;  color:$primary-light!important
        }
@include media-breakpoint-down(sm) {
            margin-bottom: 10px;
            }

        
    }
}

.form-label-right{

    /*div{
        @include media-breakpoint-down(md) {
            display:block!important; margin-bottom: 10px;;
            }
            @include media-breakpoint-down(md) {
                display:inherit!important; margin-bottom: 10px;;
                }

    }*/
}


.fc-button.fc-button-active{ background-color: $primary-light!important; color:$white!important;}
.fc-timeGridWeek-button.fc-button-primary, .fc-timeGridDay-button.fc-button-primary{ background-color: $dark-inverse!important; color:$primary-light!important;}
.fc-timeGridWeek-button.fc-button-primary:hover, .fc-timeGridDay-button.fc-button-primary:hover{ background-color: $primary-light!important; color:$white!important;}

.tooltip-inner { max-width: 300px;}
.tooltip-inner{
    .mt-4{ margin-top: 2px!important;}
}

.fc-scrollgrid tbody tr:hover { background-color: $white!important;}