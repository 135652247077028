//
// Base
//

// Body
body {
	background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			
		}

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Fixed Subheader
		.header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}


.table th, .table td { white-space: nowrap;}



// .form{ position: relative;}

// .form input[type="file"]{
//     z-index: 999;
//     line-height: 0;
//     font-size: 50px;
//     position: absolute;
//     opacity: 0;
//     filter: alpha(opacity = 0);-ms-filter: "alpha(opacity=0)";
//     cursor: pointer;
//     _cursor: hand;
//     margin: 0;
//     padding:0;
//     left:0;
// }

// .add-photo-btn{
//     position:absolute;
//     overflow:hidden;
//     cursor:pointer;
//     text-align:center;
// 	left: 0px ;
   
// }
// .form input[type="text"]{
// 	left: 85px;
//     position: relative; border:1px solid #E4E6EF; border-radius: 4px; height: 36px; background: white;

// }

// .form input[type="text"]:focus-visible{border:1px solid #E4E6EF!important; }



.grown-card{
	.dropdown-menu{
		@include media-breakpoint-down(sm) {
			min-width: 100vw !important;;
		}
		&.dropdown-menu-sm{
			@include media-breakpoint-down(sm) {
				width: 100%!important;
			}

		}
		.rdrDefinedRangesWrapper{
			@include media-breakpoint-down(sm) {
				width: 100%!important;
				border-right: 0px solid #000!important;
			}
		}
		.rdrStaticRangeLabel {
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
			
			
		}
		
		.rdrMonths {
			@include media-breakpoint-down(sm) {
				display: block!important;
			}
		}

		.rdrCalendarWrapper {
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			
			
		}

		.rdrMonth {
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
		.rdrNextButton {
			@include media-breakpoint-down(sm) {
				position: absolute;
				right: 0px;
				top: 71%;
			}
			
		}
		
	}
.rdrDateRangePickerWrapper {
	@include media-breakpoint-down(sm) {
		display: block;
	}    
}

.card{border: 0px solid #000!important;}

}


.header-fixed {
	.wrapper {
		padding-top: 60px!important;
	  }
}

.topbreadcumb{
margin-bottom: 15px;
	@include media-breakpoint-down(xs) {
		//flex-direction: column;
	}  
}

.MuiInput-underline{
	&:before{ display: none!important;}
	&:after{ display: none!important;}
}

.MuiSelect-select:focus {background-color:transparent!important;}

.offcanvas-content{
	&.newprofiel{
		div {
			overflow-x: inherit !important;
		}
	}

	&.tableover{
		div {
			overflow-x: inherit !important;
		}
	}
}

.heightCon{
	height:400px !important;
	@include media-breakpoint-down(sm) {
		height:150px !important;
	
	}   
}


.select__control{ min-height: 20px!important;}
.select__indicators{
    height: 25px;
	// padding: 4px;
}
.select__value-container {
    height: 20px;
}


