.setting-box{
a{ color:#3F4254; 
    .setting-opt{ position: relative; padding-left: 70px;

        figure{ position: absolute; left: 0px;
        .MuiSvgIcon-root{ font-size: 50px;;}
        }
    }

    &:hover{
        .card{box-shadow: 1px 3px 16px 1px rgba(0,0,0,0.11);}
    }
}
}

.makeStyles-root-1{ margin-left: auto!important; }

.table-pagging{ border-top: 1px solid #EBEDF3; padding-top: 15px;;
    ul{padding:0px; margin:0px; list-style: none;
    li{padding:0px; margin:0px 5px; list-style: none; display: inline-block; font-size:14px;
    a{ color:#000;
    
     &.active { background-color:#ff6b25; border-radius: 100%; color:#fff; width: 20px; height: 20px; display: block; 
        text-align: center;}
}
    
    }
    }
}

