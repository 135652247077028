.option {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /* Adjust height as needed */
  }
  
  .option.selected {
    background-color: #007bff; /* Bootstrap primary color or any color you prefer */
    color: white;
    border-color: #007bff;
  }
  
  .option:not(.selected):hover {
    background-color: #f0f0f0; /* Light grey for hover effect */
  }
  