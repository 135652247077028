.list-posiition{ 
    position: relative;

    .pont-icon{
        position: absolute; top:0px; right: 0px;
    }
}

.text-green{ color: #09bb26;}
.text-red{ color: #ff0707;}


.carddtl-box{ border:1px solid $light-gray; padding: 10px; margin: 0px 5px; font-size:12px; line-height: 18px; height: 110px;}
.carddtl-box:hover{ background-color:$dark-inverse;}


.customer-field{

    @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
}


.customer-pay {
    //.card{ min-height: 600px;}
}

