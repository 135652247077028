.customer-search {
  width: 250px;
  margin-left: auto;
  .input-group-text {
    background-color: #fff !important;
    padding: 2px 5px;
    border: 0px solid #000;
  }
  .form-control {
    border: 0px solid #000;
  }
}

.btn-sm {
  padding: 0.35rem 0.75rem;
}

.order-collection {
  position: relative;
  .form-control {
    height: calc(0.8em + 1.3rem + 2px);
    padding: 0.25rem 0.5rem;
  }

  .edit-icon {
    position: absolute;
    top: -56px;
    right: 10px;
  }
}

.card-head {
  .card-header {
    padding: 0px;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
}

.distance {
    border-bottom: dotted 1px #000;
    height: 40px;
    width: 70%;
    margin: 0px auto 0px auto;
    @include media-breakpoint-down(lg) {
      width: 89%;
    }
}

.order-summary {
  .card-header {
    padding: 0px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    .order-search {
      width: 250px;
      margin-left: auto;
      border: 1px solid #e4e6ef;
      border-radius: 4px;
      .input-group-text {
        background-color: #fff !important;
        padding: 2px 5px;
        border: 0px solid #e4e6ef;
      }
      .form-control {
        border: 0px solid #000;
      }
    }
  }
}

.prod-search {
  @include media-breakpoint-down(sm) {
    display: block !important;
    text-align: center;
  }

  .order-search {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
}

.summerytable {
  td {
    padding: 0.75rem!important;
  }
  th {
    padding: 0.75rem!important;
  }
}
.qty-group {
  .form-control {
    border: 0px solid #000;
    width: 50px;
    height: 17px;
    padding: 0px;
    margin-right: 28px;
  }
  .input-group-text {
    border: 0px solid #000;
    position: relative;
    height: 17px;
    padding: 0px;
  }
  .toparrow {
    position: absolute;
    top: -4px;
    right: 10px;
    transform: rotate(180deg);
    z-index: 1;
  }
  .downparrow {
    position: absolute;
    bottom: -4px;
    right: 10px;
    z-index: 1;
  }
}
.bord-top {
  border-top: 0px solid #000 !important;
}

.driver-collection {
  .btn-sm {
    padding: 0.35rem 0.5rem;
  }
}

.distance-head {
  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.offer-code {
  @include media-breakpoint-down(lg) {
    margin-top: 10px;
  }
  .form-control {
    width: 100px;

    @include media-breakpoint-down(lg) {
      width: 110px;
    }
  }
}

.paymt-btn {
  @include media-breakpoint-down(xl) {
    margin-top: 15px;
  }
}

.collection-form {
  display: flex;
  justify-content: space-between;
  .MuiFormControl-fullWidth {
    width: auto;
  }
  .form-control {
    width: 120px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.available-slot {
  text-align: center;
  @include media-breakpoint-down(lg) {
    margin-top: 15px;
  }

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
      padding: 0px;
      margin: 0px 0px 10px 0px;
      list-style: none;
      display: block;
      text-align: center;
      @include media-breakpoint-down(lg) {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}

.neworder-tab {
  .nav {
    &.nav-tabs {
      .nav-item {
        margin-bottom: 15px;
        &.nav-link {
          border: 0px solid #000 !important;
          border-radius: 4px !important;
          padding: 4px 8px 3px 8px !important;
          background-color: #e4e6ef;
          color: #000;
          font-weight: 500;
          font-size: 12px;
          &:hover {
            background-color: $primary-light;
            color: #fff;
          }
          &.active {
            background-color: $primary-light;
            color: #fff;
          }
        }
      }
    }
  }
}

.neworderdtl-tab {
  .nav {
    &.nav-tabs {
      .nav-item {
        margin-bottom: 15px;
        &.nav-link {
          border: 0px solid #000 !important;
          border-radius: 4px !important;
          padding: 10px 12px !important;
          background-color: #242939;
          color: $white;
          font-weight: 500;
          font-size: 12px;
          &:hover {
            background-color: $primary;
            color: #fff;
          }
          &.active {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }
  }
}

.neworderdtl-tab {
  position: relative;
  .edit-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.collection-area {
  min-height: 603px;
}

.order-distance {
  h5 {
    display: inline-flex;
    width: 50%;
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }
  .nav-tabs {
    border-bottom: 0px solid #000;
    display: flex;
    justify-content: flex-end; // override it from end to flex-end Error:(71679:3) end value has mixed support, consider using flex-end instead
    position: relative;
    top: -55px;
    @media only screen and (max-width: 1440px) {
      top: -32px; 
    }

    @include media-breakpoint-down(lg) {
      top: -24px;
      padding-bottom: 15px;
    }
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      text-align: right;
    }
    .nav-link {
      display: inline-block;
    }
  }

  .tab-content {
    margin-top: -63px;
  }
}

.neworder-tab {
  position: relative;
  .edit-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.customer-order .table:last-child td {
  border-top: 0px solid #000 !important;
}

.storage {
  .form-control {
    width: 80px;
    margin-left: 1px;
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.storage-option {
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.cart-icon {
  .svg-icon {
    position: relative;
    top: -3.5px;
  }
}

.collection-date {
  text-align: center;

  .react-datepicker {
    width: 100%;
    height: 100%;
    border: 1px solid #e4e6ef !important;
    @include media-breakpoint-down(md) {
      width: inherit;
      height: inherit;
      margin: 0px auto;
    }
    .react-datepicker__month-container {
      width: 99.98%;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.8rem !important;
    line-height: 2.8rem !important;
    font-size: 13px;
    @include media-breakpoint-down(sm) {
      width: 2.6rem !important;
      line-height: 2.7rem !important;
      font-size: 13px;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: $primary-light !important;
    color: #fff;
  }
  .react-datepicker__header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #e4e6ef !important;
  }
}

.input-date {
  .datepicker {
    .react-datepicker__input-container {
      .form-control {
        width: 100% !important;
      }
    }
  }

  .react-datepicker {
    width: 100%;
    height: 50%;
    border: 1px solid #e4e6ef !important;
    .react-datepicker__month-container {
      width: 99.98%;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem !important;
    line-height: 1.9rem !important;
    font-size: 13px;
    @include media-breakpoint-down(sm) {
      width: 2.6rem !important;
      line-height: 2.7rem !important;
      font-size: 13px;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: $primary-light !important;
    color: #fff;
  }
  .react-datepicker__header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #e4e6ef !important;
  }
}

.order-flt {
  display: flex;
  @include media-breakpoint-down(md) {
    display: block !important;
    margin-top: 5px;
  }
}

.promt-btnarea {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    display: block !important;
    justify-content: inherit !important;
    text-align: center;
  }

  .ordpromtbut {
    width: 260px;
    display: flex;
    @include media-breakpoint-down(lg) {
      margin-bottom: 15px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block !important;
    }
    .form-control {
      width: 66px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: block !important;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
        width: 100%;
        margin-right: 0px !important;
      }
    }
    .ml-3 {
      @include media-breakpoint-down(sm) {
        margin-left: 0px !important;
      }
    }
  }
}

.order-customer .card {
  min-height: 340px;
}

.order-payment .card {
  min-height: 538px;
}
.order-distance .card {
  min-height: 326px;
}
.customercarddtl .card {
  min-height: 240px;
}
.customerbilling .card {
  min-height: 250px;
}
.customercoll .card {
  min-height: 195px;
}
.newordnote {
  min-height: 200px;
}
.newordnoteCon {
  min-height: 160px;
}
.gutter-c {
  margin-bottom: 11px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 25px;
  }
}
.order-feed .card {
  min-height: 548px;
}

.summer-footer {
  display: flex;
  justify-content: space-between;
}

.summer-footer {
  // @media only screen and (max-width:1680px) {
  //   flex-direction: column;
  // }
  .btn{margin-bottom: 5px;}
}

//.offcanvas-content div {overflow-x: inherit !important;}

.carddtl-box {
  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
}

.newordecoll {
  .MuiSelect-select.MuiSelect-select {
    padding-top: 10px;
  }
}

.storage-process {
  width: 300px;
  text-align: right;
}

.add-edit-storage {
  margin-top: 25px;
  .makeStyles-paper-2 {
    width: 236px;
  }

  .MuiListItemIcon-root {
    min-width: 40px;
  }
  .PrivateSwitchBase-root-4 {
    padding: 2px;
  }

  .storageqty {
    width: 60px !important;
    height: 30px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .MuiButton-outlinedSizeSmall {
    background-color: $primary-light !important;
    color: $white;
    width: 100%;
    border: 0px solid $primary-light !important;
    &.MuiButton-outlined {
      &.Mui-disabled {
        border: 0px solid $primary-light !important;
        background-color: $dark-inverse !important;
        width: 100%;
        color: $primary-light;
      }
    }
  }
  .MuiGrid-container {
    @include media-breakpoint-down(sm) {
      flex-direction: column !important;
    }
  }

  .makeStyles-paper-2 {
    scrollbar-width: thin;
  }

  .MuiGrid-spacing-xs-1 {
    > .MuiGrid-item {
      padding: 1px;
      border-radius: 10px;
    }
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;

    border: 1px solid #e4e6ef; width: 100%;
  }

  .transfer-list-title {
    border: 0px;
    padding: 5px;
  }
}

.add-form {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  .notesarea {
    width: 244px;
    @include media-breakpoint-down(md) {
      width: 45%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    textarea {
      height: 101px;
    }
  }
  .weightarea {
    width: 236px;
    @include media-breakpoint-down(md) {
      width: 45%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.statusarea {
  span {
    width: 80px;
    display: inline-block;
  }
  .progress {
    display: inline-flex;
    width: 100px;
  }
}

.qty-group.input-group .form-control {
  background-color: transparent;
}

.loader-dropdown {
  .css-yk16xz-control {
    border: 1px solid #e4e6ef !important;
    min-height: 30px !important;
    height: 30px !important;
  }
  .css-tlfecz-indicatorContainer {
    padding: 3px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
  }
  .css-g1d714-ValueContainer {
    height: 26px;
  }
  .css-6q0nyr-Svg {
    height: 16px;
  }

  .css-1pahdxg-control {
    &:hover {
      border: 0px solid #000 !important;
      min-height: 30px !important;
    }
  }
  .select_control--is-focused {
    border: 0px solid #000 !important;
    min-height: 30px !important;
    height: 30px !important;
  }
  .css-1wa3eu0-placeholder {
    color: rgba(0, 0, 0, 0.87);
  }
  .css-1pahdxg-control {
    border: 1px solid #e4e6ef !important;
    box-shadow: none !important;
    min-height: 30px !important;
    height: 30px !important;
  }
}

.neworderdtl-tab {
  .MuiSelect-icon {
    top: calc(50% - 10px);
  }
}

.add-edit-storage {
  .form-control {
    width: 236px;
  }
}

.loader-dropdown {
  .btn-light {
    border: 1px solid #e4e6ef;
    background-color: transparent;
    height: 30px;
    padding: 0px 10px;
    width: 100%;
    text-align: left !important;
    position: relative;
    &::after {
      position: absolute;
      right: 5px;
      font-size: 10px;
      top: 9px;
    }
  }
  .dropdown-inline {
    display: inline-block;
    width: 100%;
  }

  .navi {
    .navi-item {
      padding: 8px;

      &:hover{
        background-color: rgba(0, 0, 0, 0.08)
      }
  }
  }
}

.payment-radio{
  label{
    @include media-breakpoint-down(sm) {
     margin-left: 10px;
    }

  }
  

}


.newordertable {
  thead {
    tr {
      th {
        &:nth-child(7) {
          min-width: 150px;
        }
      }
    }
  }
}


.summeryromtbut{  margin-bottom: 5px;
.form-control  {width: 127px; margin-right: 8px;
  &.addhoc{
    @include media-breakpoint-down(xl) {
      width: 77px!important;
    }
  }

}

}

.add-discount{width: 390px; margin-bottom: 5px;}

.offerCode{
  width:127px !important
}

.save-draft {
  width: 101px;
  margin-left: 10px; height: 37px;
}
.sapply-code{width:120px;}
.snd-code{width:112px; height: 37px;}
.sum-payment{width:70px; padding: 0.65rem 0rem!important; height: 37px;}


.table th, .table td {
  white-space: nowrap;
}

.summerytable {

  &.table {
    th {
      white-space: inherit!important;
    }
    td {
      white-space:inherit !important;
    }

    .productsta { width:115px}
    .cost { width:100px}
  }
}

.adhoc-charge{ display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
    width: 100%;

  }
  @include media-breakpoint-down(md) {
    display: flex;
    width: 100%;
  }
  .form-inline{flex-flow:inherit!important}
}

#btn-neworderpage .adhoc-charge{ display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
    width: 100%;

  }

  @include media-breakpoint-down(md) {
    width: 100%;
    display: flex;
    position: relative;
    flex-flow: wrap;
    justify-content: space-between;
  }

  .orderSummaryAdButton{
    @include media-breakpoint-down(md) {
      margin-right: 0px !important;
    }
  }
}



