//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#ff6601 ; // Bootstrap variable
$primary-hover:    									#000000 ; // Custom variable
$primary-light:    									#ff6601; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable
$dark-inverse:  									#000000  ; // Custom variable
$white:  							        		#fff; // Custom variable
$light-gray:                                        #f3f6f9; 

