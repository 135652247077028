@include media-breakpoint-up(lg) {
  .header-fixed {
    z-index: 999 !important;

    .wrapper {
      padding-top: 115px;
    }
  }
}

.bg-blue {
  background-color: #e1f0ff;
}

.bg-light-pur {
  background-color: #eee5ff;
}

.bg-light-gry {
  background-color: #f7f7f8;
}

.text-blue {
  color: #3699ff !important;
}

.text-purp {
  color: #a481eb !important;
}

.grown-card {

  h3,
  .h3 {
    @include media-breakpoint-down(xl) {
      font-size: 1rem;
    }
  }
}

.ratio {
  font-size: 40px;

  @include media-breakpoint-down(xl) {
    font-size: 30px;
  }
}

[aria-label*="asc"].sortable {
  svg {
    g {
      path#Path-104 {
        fill: #ff7036 !important;
      }
    }

    g {
      path#Path-104-Copy {
        fill: #e0e0e0 !important;
      }
    }
  }
}

[aria-label*="desc"].sortable {
  svg {
    g {
      path#Path-104 {
        fill: #e0e0e0 !important;
      }
    }

    g {
      path#Path-104-Copy {
        fill: #ff7036 !important;
      }
    }
  }
}

//.custom-select {
// background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1rem center/8px 10px;

// }

.day-dropdown {
  .card {
    @include media-breakpoint-down(md) {
      border: 0px solid #ebedf3;
    }
  }

  .dropdown-menu-sm {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100vw;
    }
  }

  .rdrDateRangePickerWrapper {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .rdrMonths {
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .rdrMonthAndYearWrapper {
    @include media-breakpoint-down(md) {
      width: 100vw;
    }
  }

  .rdrMonth {
    @include media-breakpoint-down(md) {
      width: 100vw;
    }
  }

  .rdrDefinedRangesWrapper {
    @include media-breakpoint-down(md) {
      width: 100%;
      border-right: solid 0px #eff2f7;
    }
  }

  .rdrStaticRangeLabel {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .rdrNextButton {
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 0px;
      top: 71.5%;
    }
  }
}

.MuiInputBase-root {
  &.form-control {
    padding: 0px !important;
  }
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  padding-left: 11px;
}

.MuiPaper-rounded {
  border-radius: 0px;
  margin-top: 0px;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

select {
  &.form-control {
    padding: 0.45rem 1rem;
  }
}

.table th,
.table td {
  vertical-align: middle;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}

.offcanvas-footer {
  position: absolute;
  bottom: 0px;
  width: 95.4%;
  height: 50px;
  background-color: #fff;

  @include media-breakpoint-down(md) {
    width: 93.4%;
  }

  .offcanvas-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.offcanvas-content {
  margin-bottom: 80px !important;
}

.btn-dark {
  background-color: $dark-inverse !important;
  border: 1px solid $dark-inverse !important;
  color: $primary-light !important;

  &:hover {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
    color: $white !important;
  }
}

.btn-primary {
  background-color: $primary-light !important;
  border: 1px solid $primary-light !important;

  &:hover {
    background-color: $dark-inverse !important;
    border-color: $dark-inverse !important;
    color: $white !important;
  }

  &:focus {
    background-color: $dark-inverse !important;
    border-color: $dark-inverse !important;
    color: $white !important;
  }
}

.btn-primaryCus {
  background-color: $primary-light !important;
  border: 1px solid $primary-light !important;
  color: #fff !important;

  &:hover {
    background-color: $dark-inverse !important;
    border-color: $dark-inverse !important;
    color: $white !important;
  }
}

.frans-collap {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0px 13px;
    font-size: 14px;
  }

  .makeStyles-heading-2 {
    font-size: 1rem;
  }
}

.switch input:empty~span:after {
  height: 18px;
  width: 18px;
  background-color: $primary-light;
}

.switch.switch-outline.switch-primary input:checked~span:after {
  background-color: $primary-light;
}

.switch.switch-outline.switch-primary input:checked~span:before {
  height: 24px;
  border: 2px solid $primary-light;
}

.switch.switch-outline.switch-primary input:empty~span:before {
  height: 24px;
}

.switch input:checked~span:after {
  margin-left: 32px;
  background-color: $primary-light;
}

label+.MuiInput-formControl {
  margin-top: 0px !important;
}

.svg-icon.svg-icon-primary svg g [fill] {
  fill: $primary-light !important;
}

.text-primary {
  color: $primary-light !important;
}

.symbol.symbol-light-primary .symbol-label {
  background-color: $dark-inverse !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled).active {
  color: #ffffff !important;
  background-color: $primary-light !important;
  border-color: $primary-light !important;
}

a {
  color: $primary-light;
}

.pointer {
  cursor: pointer;
}

.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
  background-color: $dark-inverse;
}

/*input[type='checkbox'] {
        accent-color: #1BC5BD !important; border-color: #f0ad4e!important; border-radius: 4px;
    }*/

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: $primary-light !important;
  border-color: $primary-light !important;
}

.scrolltop {
  background-color: $primary-light !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: black !important;
}

/*input[type='checkbox']  {
        accent-color: #0891b2; opacity: 0.65;
    }*/

.custom-checkbox {
  align-items: center;

  label {
    margin-bottom: 0px;
    font-weight: 400 !important;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background: $primary-light !important;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: $primary-light !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDateDisplayItemActive,
.rdrDay {
  border-color: $primary-light !important;
}

.rdrInRange span {
  border-color: $primary-light !important;
}

tbody tr:hover {
  color: #3f4254;
  background-color: #e4e6ef;
}

.card-title .font-size-sm {
  font-size: 1.1rem;
  font-weight: 700;
}

.des-checkbox {
  input {
    -webkit-appearance: none;
    width: 1.15rem;
    height: 1.15rem;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    vertical-align: sub;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    outline: none;
    background-color: #f7f7f8;

    &:checked {
      background-color: #1bc5bd;
      border-color: #1bc5bd;

      &+label {
        line-height: 7px;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  label {
    padding: 0px 0px 0px 23px;
    display: inline-block;
    font-size: 13px;
    width: 100%;
    user-select: none;
    cursor: pointer;
    font-weight: 400;
    line-height: 7px;
  }
}

label.checkbox {
  font-weight: 400;
}

.checkbox>input:checked~span {
  background-color: $primary-light !important;
}

/*.offcanvas-content >div {
        padding-right: 23px;
    }*/

.closeicon {
  background-color: rgba($color: #f64e60, $alpha: 0.35);
  border-radius: 100%;
  position: relative;

  #Rectangle-185 {
    position: absolute;
    top: -5px;
  }
}

.card-title span.mt-3 {
  margin-top: 0.25rem !important;
}

.scroll {
  @include media-breakpoint-down(sm) {
    overflow-x: hidden !important;
  }
}

.offcanvas-content div {
  overflow-x: hidden !important;
}

.podfilter {
  @include media-breakpoint-down(md) {
    display: block !important;
    margin-bottom: 5px;
  }

  span {
    display: block !important;
  }

  .react-datepicker-wrapper {
    display: block;
  }
}

.fc-button-primary {
  background-color: #ff6601 !important;
  border-color: $dark-inverse !important;
  color: white !important;
}

.fc-button-active {
  background-color: $dark-inverse !important;
  color: $primary-light !important;
}

.date-picker .react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
  width: 100%;
  position: relative;
}

.offcanvas-content.date-picker div {
  overflow-x: visible !important;
}

.offcanvas-content.scrollhd div {
  overflow-x: visible !important;
}

.date-picker .react-datepicker__day-name,
.date-picker .react-datepicker__day,
.date-picker .react-datepicker__time-name {
  line-height: 1.4rem;
  width: 2.5rem;
}

// .date-picker .react-datepicker { width: 300px; border: 1px solid $primary-light;}

// .date-picker .react-datepicker__header {
//     border-bottom: 1px solid $primary-light;
//     padding-top: 8px;
//     width: 298px;
// }

.date-picker .react-datepicker__day--keyboard-selected,
.date-picker .react-datepicker__month-text--keyboard-selected,
.date-picker .react-datepicker__quarter-text--keyboard-selected {
  background-color: $primary-light;
}

.card.card-custom>.card-header {
  min-height: 24px;
}

.calendr {
  position: relative;

  svg {
    position: absolute;
    right: 5px;
    top: 7px;
  }
}

[role*="document"].modal-dialog {
  .modal-header {
    border-bottom: 0px solid #000 !important;
  }

  .modal-footer {
    border-top: 0px solid #000 !important;
  }
}

.mmb-10 {
  @include media-breakpoint-down(md) {
    margin-bottom: 3.5rem !important;
  }
}

// makeStyles-completed-176
[class|="makeStyles-completed"] {
  font-size: 3rem !important;
}

.steaperui {
  .MuiSvgIcon-root {
    // font-size: 2rem!important;
    position: relative;
    top: 3px;

    &.MuiStepIcon-active {
      font-size: 3rem !important;
      color: $primary-light !important;
      position: relative;
      top: -3px;
      z-index: 99;

      .MuiStepIcon-text {
        fill: $white !important;
        font-size: 1rem;
      }
    }

    &.MuiStepIcon-completed {
      font-size: 2rem !important;
      color: $primary-light !important;
      position: relative;
      top: 3px;

      .MuiStepIcon-text {
        fill: $white !important;
        font-size: 1rem;
      }
    }
  }

  .MuiStepConnector-alternativeLabel {
    top: 12px !important;
    left: calc(-50% + 10px);
    right: calc(50% + 10px);
  }
}

.symbol-light-blue {
  background-color: #e1f0ff !important;
  color: #3699ff !important;

  .symbol-label {
    color: #3699ff !important;
    background-color: #e1f0ff !important;
  }
}

//Transfer list

.transfer-list-title {
  color: #3f4254;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ebedf3;
  border-bottom: 0;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}

.minusicon {
  .svg-icon-danger {
    background-color: #ffe2e5;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    position: relative;
    display: block;

    svg {
      height: 14px !important;
      width: 14px !important;
      position: absolute;
    }
  }
}

.linktxt {
  color: #3f4254 !important;
  text-decoration: none;

  &:hover {
    color: $primary-light !important;

    .svg-icon {
      svg {
        g [fill] {
          fill: $primary-light !important;
        }
      }
    }
  }
}

.offcanvas-content.scrollhd div {
  overflow-x: visible !important;
}

//calendar
.react-datepicker__day--highlighted-custom-1 {
  color: red !important;
}

.order-progress {
  @include media-breakpoint-down(sm) {
    width: 60px !important;
  }
}

/*.table {
        @include media-breakpoint-down(md) {
        max-width: 1200px;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            }
    }*/

.MuiPaper-root {
  scrollbar-width: thin !important;
}

.timelinesect {
  .timeline.timeline-6 {
    position: relative;
    height: inherit !important;
    overflow-y: inherit !important;
    /* height: 419px; */
    /* overflow-y: scroll; */
  }

  .timelinelist {
    height: 419px;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    overflow-x: hidden;
  }
}

.add-edit-storage {
  .MuiGrid-align-items-xs-center {
    align-items: flex-start;
  }
}

//btton
.btn-outline-dark {
  background-color: $dark-inverse !important;
  border: 1px solid $dark-inverse !important;
  color: $primary-light !important;

  &:hover {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
    color: $white !important;
  }

  &:focus {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
    color: $white !important;
  }

  // &:hover{ background-color:$primary-light!important; border-color:$primary-light!important; color:$white!important  }
}

.btn.btn-outline-secondarys {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: black !important;

  &:hover {
    background-color: white !important;
    border-color: lightgray !important;
    color: black !important;
  }

  // &:focus{ background-color:$primary-light!important; border-color:$primary-light!important; color:$white!important  }
}

// .btn.btn-outline-secondarys:focus{
//     background-color:#ff9f60!important; border-color: $dark-inverse!important; color:white!important

// }

//dashboard Hq Sales Table border
.table-outline-grey {
  border: solid 1px lightgrey;
}

//snackbar
.MuiAlert-filledError {
  background-color: red !important;
}

.btnClick {
  background-color: $primary-light !important;
  border-color: $primary-light !important;
  color: $white !important;
}

//icon of invalid field in form
.form-control.is-invalid {
  background-position: right calc(0.775em + 0.325rem) center !important;
}

.form-control.is-valid {
  background-position: right calc(0.775em + 0.325rem) center !important;
}

//franchise detail page depot multiselect
// .select__control--is-focused{

//     .select__placeholder{
//         position: inherit !important;
//     }
// }
// .select__control{

//     .select__placeholder{
//         // margin-top: 5px;
//         position: inherit !important;
//     }
// }

// .offcanvas-rightss input.form-control.form-control {
//     margin-left: 0.05em;
//     width: 99.50%;
// }

// .deport-srch .select__menu{height:150px; overflow:hidden;}

// .deport-srch .select__control--is-focused .css-1wa3eu0-placeholder {
//     top: 50%;
// }

// .deport-srch .css-g1d714-ValueContainer{overflow: initial;}

//ckEditor Height increase

.ck-editor__editable {
  min-height: 200px;
}

a {
  text-decoration: none !important;
}

.MuiMenu-paper {
  max-height: calc(70% - 96px) !important;
}

//deal detail card / contact detail card

.detailCard {
  @include media-breakpoint-down(xxl) {
    height: 640px;
  }

  @include media-breakpoint-down(xl) {
    height: 100%;
  }

  // @include media-breakpoint-down(lg) {
  //     height: 100%;
  // }
  // @include media-breakpoint-down(md) {
  //     height: 100%;
  // }
  // @include media-breakpoint-down(sm) {
  //     height: 100%;
  // }
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: #f3f6f9;
  cursor: default;
}

.MuiInputLabel-outlined {
  z-index: 1;
  // transform: translate(14px, 15px) scale(1) !important;
  // .MuiInputLabel-shrink{
  //     transform: translate(14px, -6px) scale(0.75) !important;

  // }
  pointer-events: none;
}

.customer-search {
  .MuiInputBase-root {
    padding: 2px !important;
  }
}

.product-search {
  .MuiInputBase-root {
    padding: 2px !important;
  }
}

.overflowCanva {
  overflow-x: scroll !important;
}

.rc-time-picker-panel-select {
  width: 54px !important;
}

.rc-time-picker-input {
  height: 39px !important;
}

.rc-time-picker {
  width: 100% !important;
}

.rc-time-picker-panel-inner {
  margin-top: 39 px !important;
}

// .MuiInputLabel-outlined{
//     top: -6px !important;
//     .MuiInputLabel-shrink{
//         top:0 !important;

//     }
// }
.customer-search .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1) !important;
}

.customer-search .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.product-search .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1) !important;
}

.product-search .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.product-search {
  width: 68%;
  margin-left: auto;

  .input-group-text {
    background-color: #fff !important;
    padding: 2px 5px;
    border: 0px solid #000;
  }

  .form-control {
    border: 0px solid #000;
  }
}

.product-search .MuiAutocomplete-fullWidth {
  width: 400px !important;
}

.customerContactDeal .demoCustomer {
  width: 80% !important;
  right: -80% !important;
}

.customerContactDeal .offcanvas-on {
  width: 80% !important;
  right: -0% !important;
}

.my-modal {
  @media (max-width: 1199px) {
    max-width: 100% !important;
  }

  max-width: 80% !important;
}

.my-transfer-modal {
  @media (max-width: 1199px) {
    max-width: 60% !important;
  }

  max-width: 60% !important;
}


.my-modal60 {
  @media (max-width: 1199px) {
    max-width: 100% !important;
  }

  max-width: 60% !important;
}

.my-modal80 {
  @media (max-width: 1199px) {
    max-width: 100% !important;
  }

  max-width: 60% !important;
}

.modal-height {
  height: 70%;
}

.demoHeader {
  justify-content: end;
  padding-bottom: 2px !important;
}

.MuiAutocomplete-clearIndicatorDirty {
  right: -10px !important;
}

.MuiAutocomplete-endAdornment {
  right: 0px !important;
}

.customModal {
  @media (max-width: 1199px) {
    max-width: 100% !important;
  }

  max-width: 70% !important;
}

.customloader {
  width: 100%;
  height: 100vh;
  text-align: center;

  .customSpinner {
    margin-top: 20%;
    height: 50px;
    width: 50px;
    font-size: 30px;
    color: #007bff !important;
  }
}

.custom-scroll {
  overflow-y: auto !important;
  height: auto;
  max-height: 75vh;
}

.franchise .MuiIconButton-edgeEnd {
  margin-right: 113px !important;
}

.storageEdit {
  width: 60%;
  // justify-content: center;
  margin: auto;
  background-color: #e4e6ef;
  padding: 1px;

  .storageCardTitle {
    display: flex;
    justify-content: space-between;
    padding: 18px;
  }

  .storageCardBody {
    display: flex;
    justify-content: space-between;
  }
}

.customCheck {
  .table-responsive {
    .customColor {
      color: grey !important;
    }
  }
}

.wdt-100Im {
  width: 100% !important;
}

// .fc-day-today {
//     background-color: #ffffff !important;
//   }

//   .fc-timeline-slot{
//     border: white !important;
//   }

.fc-timeline-slot {
  border-color: white !important;
}

#direction-example {
  width: 100% !important;
}

a.fc-timeline-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
  margin-right: 0px !important;
}

#mui-component-select-depot_id {
  padding-top: 10px;
}

.fc-toolbar-chunk {
  div {
    display: flex;
  }
}

.fc-prev-button {
  margin-right: 30px !important;
}

.fc-next-button {
  margin-left: 30px !important;
}

.fc-timeline-event-harness:hover {
  background-color: lightgrey;
  color: lightgrey;
}

.fc .fc-toolbar-title {
  font-size: 1.35em !important;
}

.fc-timeline-event .fc-event-title {
  font-size: 14px !important;
  // font-weight: 500 !important;
}

.clicked-event {
  background-color: blue;
  color: white;
  font-weight: bolder !important;
}

.fc-toolbar-chunk div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.fc-toolbar-title {
  font-size: 1.35em !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px solid #ddd;
  border: 0px solid var(--fc-border-color, #ddd);
}

.fc-theme-standard .fc-scrollgrid {
  border: 0px solid #ddd;
  border: 0px solid var(--fc-border-color, #ddd);
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  // background: transparent;
}

td.fc-timeline-lane.fc-resource {
  border-bottom: 1px solid #000;
  padding-top: 10px;
}

td.fc-datagrid-cell.fc-resource {
  border-bottom: 1px solid #000;
  padding-top: 10px;
}

.fc .fc-resource-timeline-divider {
  width: 3px;
  cursor: col-resize;
}

.fc-scrollgrid-section tbody tr:hover {
  color: #3f4254;
  background-color: transparent;
}

.fc-next-button,
.fc-prev-button {
  border-radius: 100% !important;
  margin-top: 0px !important;
  height: 34px;
  width: 34px;
  // display: flex;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

.fc-resource-timeline.fc-resource-timeline-flat.fc-timeline.fc-timeline-overlap-enabled.fc-resourceTimeline-view.fc-view {
  margin-bottom: 24px;
}

.fc-theme-standard {
  max-height: 460px;
  overflow-y: scroll;
}

.fc-scrollgrid tbody tr:hover {
  background-color: transparent !important;
}

td.fc-datagrid-cell.fc-resource:hover {
  border-bottom: 1px solid #000;
}

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border-bottom-style: inherit !important;
}

.fc-toolbar-chunk div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-top: 4px;
}

.fc th {
  text-align: center;
  font-size: 0.944rem;
}

.fc-datagrid-cell-main .h4,
.fc-datagrid-cell-main h4 {
  font-size: 1.1rem;
}

.fc-timeline-event .fc-event-title {
  font-size: 12px !important;
}

.fc-h-event .fc-event-title-container {
  text-align: center;
}

.fc-timeline-event-harness {
  margin: 0px 5px;
}

// .fc-scrollgrid tbody tr:hover {
//     background-color: transparent !important;
// }

.fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
  justify-content: center;
}

table.fc-scrollgrid tbody tr:hover {
  background-color: transparent !important;
}

.calendarModal {
  height: 88vh;
  overflow: scroll;
}

.tooltip-inner {
  width: 500px;
  max-width: 450px;
  font-size: 12px;
}

.calendar-search {
  // width:21% !important
}

.breakeWrap {
  width: 100%;
  white-space: break-spaces !important;
}

.fc-timeline-slot-frame {
  border: 1px solid lightgrey;
  margin: 5px;
}

.orderSummaryAdButton {
  width: 20%;

  @media (max-width: 1199px) {
    width: 20%;
  }
}

.orderPro {
  width: 100%;

  @media (max-width: 1699px) {
    width: 40px;
    margin-left: 21px;
  }

  @media (max-width: 1499px) {
    width: 50px;
    margin-left: 21px;
  }

  //     // @media (max-width: 700px){
  //     //     width: 45px;
  //     //     margin-left: 16px;
  //     // }
}

label.form-check-label {
  padding: 1px;
}

.pad15 {
  padding: 15px !important;
}

.swiper {
  width: 100%;
  height: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.damageImg {
  border-radius: 10px;
}

.cardDamage {
  position: relative;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 4px;
  text-decoration: none;

  &:hover .overlay {
    //   transition: all 0.2s ease-out;
    //   box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    //   top: -2px;
    //   border: 1px solid #cccccc;
    //   background-color: white;
    opacity: 1;
  }

  // &:hover:before {
  //   transform: scale(2.15);
  // }
}

.overlay {
  background: #4643438f;
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
}

.orderActionSelect {
  flex: 1;
  text-align: left;
  margin-right: 10px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.btn-secondary {
  background-color: #242939 !important;
  color: $white !important;
  font-weight: 500 !important;

  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.btn-dark,
.sapply-code {
  color: $white !important;
  background-color: #242939 !important;
  border-color: #242939 !important;
  font-weight: 500 !important;
}

.btn.btn-primary,
.btn {
  font-weight: 500 !important;
}

.topbar-item {

  .text-muted,
  .text-dark-50 {
    color: #242939 !important;
  }
}

@media (min-width: 992px) {
  .brand-dark .brand .btn .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #fff !important;
  }
}

.customer-details-sec .svg-icon svg g [fill] {
  fill: #242939 !important;
}

.carddtl-box:hover {
  color: #fff;
}

/*15 april 2023*/
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #3f4254 !important;
  /* background-color: #242939;*/
}

.form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}

.nav .nav-link {
  color: #3f4254;
}

.MuiAutocomplete-root .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.87);
}

.customer-details-sec .svg-icon svg g [fill] {
  opacity: 1;
}

.card-title .text-muted {
  color: #3f4254 !important;
}

.timelinelist .text-dark-25 {
  color: #3f4254 !important;
}

.svg-icon svg g [fill] {
  fill: #242939;
  opacity: 1;
}

.card.card-custom .text-muted {
  color: #3f4254 !important;
}

.reporting-sec .svg-icon.svg-icon-primary svg g [fill],
.setting-sec .svg-icon.svg-icon-primary svg g [fill] {
  fill: #fff !important;
  opacity: 1;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #e4e6ef !important;
}

.steaperui .MuiSvgIcon-root {
  color: #ff6601;
}

.MuiAutocomplete-endAdornment {
  right: 5px !important;
}

.MuiSelect-icon {
  right: 5px !important;
}

#kt_change_password_panel_toggle .text-muted {
  color: #3f4254 !important;
}

.breadcrumb .breadcrumb-item a {
  color: #3f4254 !important;
}

.carddtl-box:hover .svg-icon svg g [fill] {
  fill: #fff;
  opacity: 1;
}

.setting-sec path#Fill-2 {
  fill: #000 !important;
}

.setting-sec polygon#Stroke-1 {
  fill: #000 !important;
}

.setting-sec #Fill-4 {
  fill: #000 !important;
}

#kt_quick_panelone #Combined-Shape {
  fill: #fff !important;
}

.symbol.symbol-light-primary .symbol-label {
  background-color: #242939 !important;
}

.deals-tabs .nav-tabs .nav-link img {
  filter: invert(0%) sepia(0%) saturate(7470%) hue-rotate(272deg) brightness(108%) contrast(108%) !important;
}

.deals-tabs .nav-tabs .nav-link.active img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(67deg) brightness(93%) contrast(111%) !important;
}

.summerytable thead tr th {
  color: #000 !important;
}

.summerytable tbody tr td {
  color: #000 !important;
}

.svg-icon-success.svg-icon svg g [fill] {
  opacity: revert-layer !important;
}

.hello .product-search .MuiAutocomplete-fullWidth {
  width: 460px !important;
}

.fontCustome .MuiFormLabel-root {
  font-size: 10px !important;
}

.orderTableSearch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.smallCard {
  min-height: 80px !important;
}

.text-muted-grey {
  color: #b5b5c3 !important;
}

.my-dropdown-style {
  /* add your custom styles here */
  background-color: #000;
}

.width-100px {
  width: 100px !important;
}

.marginCustom11 {
  margin-bottom: -11px;
  margin-top: -11px;
  margin-left: -11px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.pointerNone {
  pointer-events: none;
}

.MuiAlert-filledError {
  background-color: #ff6601 !important;
}

.MuiAlert-message {
  font-size: 13px !important;
}

.orderTableCard {
  background-color: #ff6601;
  color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  overflow-y: auto;
  /* Enable scrolling for the container */
  scrollbar-width: thin;
  /* Set the width of the scrollbar */
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard::-webkit-scrollbar {
  width: 10px;
  /* Set the desired width of the scrollbar */
}

#kt_quick_panel_logs_kt_OrderInvoice_panel tr td:nth-child(1) {
  max-width: 80px;
  white-space: inherit;
}

.customeScrollAuto {
  overflow: auto !important;
}

.successful-order {
  .MuiSvgIcon-root {
    color: #ff6601;
    font-size: 10.1875rem !important;
  }
}

//remove Arrow
.removeArrow::-webkit-inner-spin-button,
.removeArrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Optionally, hide the number input's increment and decrement buttons in Firefox */
.removeArrow[type="number"] {
  -moz-appearance: textfield;
}

/* Optionally, if you want to hide the increment and decrement arrows in IE and Edge */
.removeArrow::-ms-inner-spin-button,
.removeArrow::-ms-clear {
  display: none;
}

.actionEditList .dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
  content: "" !important;
}

.customRefreshIcon {
  height: 24px !important;
  /* Set the desired height (e.g., 24px) */
}

.marginLeft-18 {
  margin-left: -18%;
}

.flipIconFont15 {
  font-size: 15px !important;
  color: #5a5555 !important;
  transform: scaleX(-1);
}

.iIcon {
  font-size: 15px !important;
  margin-top: -2px !important;
}

.iIconToolTip {
  font-size: 12px !important;
  font-weight: 300 !important;
}

.grey {
  background-color: #009933;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.OB {
  background-color: #ff6601;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.grey1 {
  background-color: darkgreen;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.OB1 {
  background-color: darkorange;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.badge-danger {
  background-color: #fbdee7 !important;
  color: #dd2854 !important;
}

.badge-success {
  background-color: #c5ffe1 !important;
  color: #1a8149 !important;
}

.badge-gold {
  color: #a67c00 !important;
  background-color: #ffdc73 !important;
}

.badge-silver {
  background-color: #dcdcdc !important;
  color: #616161 !important;
}

.badge-bronze {
  background-color: #f2d9c5 !important;
  color: #a15822 !important;
}

.top-4 {
  top: -4.5px !important;
}

.lne {
  line-height: 17px;
}

// #544b4b

.disableDiv {
  pointer-events: none;
  opacity: 0.5;
}

.selectHover {
  .customTextMuted {
    color: #979799;
  }

  cursor: pointer;

  :hover {
    background: #e4e6ef !important;
    color: black !important;
  }
}

.border50 {
  border-radius: 5px 5px 0px 0px !important;
}

.newSwiper {
  height: 20vh !important;
}

.marginSite {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
  margin-right: -50px !important;
}

// .asideCustom{
//     background-color: #fff !important;
//     box-shadow: 1px 0 2px 0 rgba(0,0,40,.08), 6px 0 10px 0 rgba(0,0,40,.08);

//     transition: width .3s cubic-bezier(0,1,.55,1);
//     border-radius: 0 8px 8px 0;
// }
// .asideCustomItem{
//     background-color: #fff !important;
//     // box-shadow: 1px 0 2px 0 rgba(0,0,40,.08), 6px 0 10px 0 rgba(0,0,40,.08);

//     // transition: width .3s cubic-bezier(0,1,.55,1);
//     // border-radius: 0 8px 8px 0;
// }
// .sideMenuCus{
//     fill: black !important;
//     filter: invert(1) !important;
// }
// .blackMenu{
//     color: #000 !important;
// }

.cardDemo {
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 7px 5px -7px rgba(0, 0, 0, 0.1);
}

.cardDemo:hover {
  box-shadow: 0px 15px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1, 1);
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.alignCu {
  align-items: baseline;
}

.calendarDis {
  .fc .fc-timeline-header-row-chrono {
    display: none !important;
  }
}

.fc-timeline-slot-frame {
  height: 22px !important;
}

.backGround {
  background-color: #e4e3e3 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.doggle-toggle-dashboard {
  .dropdown-menu {

    height: 300px !important;
    width: 100% !important;
    overflow: scroll !important;
  }
}



.timeLineOrder {
  margin-left: -8px;
  /* Default margin */
  padding: 0;
}

/* Media query for screens greater than 768px */
@media (max-width: 769px) {
  .timeLineOrder {
    margin-left: 0px;
    /* Margin for screens greater than 768px */
    padding: 0 13px;
  }
}

.timeLineOrderLast {
  justify-content: center;
}

/* Media query for screens greater than 768px */
@media (max-width: 769px) {
  .timeLineOrderLast {
    justify-content: start !important;
  }
}

.timeLineOrderTime {
  margin-left: -8px;
}

/* Media query for screens greater than 768px */
@media (max-width: 769px) {
  .timeLineOrderTime {
    margin-left: 0px !important;
  }
}


.disNone {
  display: none !important;
}

.width116{
  width: 116% !important;
}
