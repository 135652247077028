.depots-pannel {
  .form-control {
    height: 30px;
    // border-right: 0px solid #e4e6ef;

    &:focus {
      border: 1px solid #e4e6ef;
      // border-right: 0px solid #e4e6ef;
    }
  }
}

.franchise-btn {
  .btn {
    padding: 0.65rem 0.5rem;
    height: 60px;
    @include media-breakpoint-down(sm) {
      padding: 0.35rem 0.5rem;
      height: 50px;
    }
  }
}

.search-input {
  border: 1px solid #e4e6ef;
  border-radius: 4px;
  position: relative;
  .form-control {
    border: 0px solid #000;
    margin-right: 25px;
    height: 36px;
  }

  i {
    position: absolute;
    right: 5px;
    top: 50%;
  }
}

.postcode-pannel {
  height: 450px;
  overflow-y: scroll;
  width: 97%;
  scrollbar-color: #eeeeee #fff;
  scrollbar-width: thin;
}

/* width */
.postcode-pannel ::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.postcode-pannel ::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
.postcode-pannel ::-webkit-scrollbar-thumb {
  background: #eeeeee;
}

/* Handle on hover */
.postcode-pannel ::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

.postcodelist {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  & > div {
    min-width: 18%;
    margin: 5px 2px;

    @include media-breakpoint-down(sm) {
      min-width: 47%;

    }
  }
  i {
    font-size: 12px;
  }
}

.deporthead {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: left;
  }

  
}

.depot-fr{
    @include media-breakpoint-down(xs) {
      }
.input-group{
    @include media-breakpoint-down(sm) {
        width:100%
      }
}

}


.postcodelist{

  .btn{ position: relative;
  i{ position: absolute; top: 12px; right: 14px;}
  }
}


.deport-srch {

  .form-group {
    width: 140px;
  }
  
}

.offcanvas-content {
  padding-left: 3rem!important;
  padding-right: 3rem!important;
}

.frans-collap{

  .MuiExpansionPanelDetails-root { 
    display:block!important;
    @include media-breakpoint-down(sm) {
      display:block!important;
    }
  }
}


#kt_quick_panel_logs_kt_franchisepostcodes_panel{
.form-control{
  @include media-breakpoint-down(sm) {
  margin-bottom: 10px;
  }

}


  
}
  
