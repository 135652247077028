

$leather: #AB987A;
$eggshell: #F5F5F5;
.Checkout {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media only screen and (min-width: 1024px) {
		
		  width: 75%;
		
	  }
	  
	  /* For tablet */
	  @media only screen and (min-width: 768px) and (max-width: 1023px) {
		
		  width: 100%;
	
	  }
	  
	  /* For mobile */
	  @media only screen and (max-width: 767px) {
		
		  width: 100%;
		
	  }
}

.Card-check {
	width: 25.68rem;
	height: 16.194rem;
	border-radius: 0.9rem;
	background: linear-gradient(110deg, #ff6601 60%, #181c32 60%);
	font-size: 0.7em;
	text-transform: uppercase;
	color: white;
	position: relative;
	box-shadow: 2px 2px 8px 0 rgba(#181c32, 0.05), 0 0 64px 0 rgba(#181c32, 0.1);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	transition: transform 0.4s ease-in-out;
	transform: translate(0, 4rem);

	.card-chip {
		width: 4.5em;
		height: 3em;
		position: absolute;
		top: 2.5rem;
		left: 2rem;
		border-radius: 0.3em;
		background: linear-gradient(135deg, #fefcea, gold 120%);
	}

	.card-logo {
		position: absolute;
		bottom: 1.5rem;
		right: 1.5rem;
		font-size: 2em;
		font-weight: 700;
		line-height: 0.9em;
		text-align: right;
	}

	.card-number {
		position: absolute;
		top: 6.5rem;
		left: 1rem;

		div {
			font-size: 2.9em;
		}
	}

	.card-holder {
		position: absolute;
		top: 10rem;
		left: 1rem;
	}

	.card-date {
		position: absolute;
		top: 12.8rem;
		left: 1rem;
	}

	.card-strip {
		background: black;
		width: 100%;
		height: 5em;
		position: absolute;
		top: 4em;
	}

	.card-logo-back {
		position: absolute;
		top: 12rem;
		right: 1.5rem;
		font-size: 2em;
		font-weight: 700;
		line-height: 0.9em;
		text-align: right;
	}

	.card-code {
		position: absolute;
		top: 12rem;
		left: 1rem;
	}

	.mono {
		font-family: "PT Mono", monospace;
		font-size: 2em;
	}

	.front,
	.back {
		transition: opacity 0s linear 0.2s, transform 0s linear 0.2s;
	}

	.back {
		opacity: 0;
	}

	&:hover {
		transform: translate(0, 4rem) scale(-1, 1);

		.front {
			opacity: 0;
		}

		.back {
			opacity: 1;
			transform: scale(-1, 1);
		}
	}
}

.Form {
	background: white;
	box-shadow: 2px 2px 8px 0 rgba(#181c32, 0.05), 0 0 64px 0 rgba(#181c32, 0.1);
	padding: 2rem 2rem 2rem 2rem;
	
	border-radius: 1rem;
	@media only screen and (min-width: 1024px) {
		
		width: 75%;
	  
	}
	
	/* For tablet */
	@media only screen and (min-width: 768px) and (max-width: 1023px) {
	  
		width: 100%;
  
	}
	
	/* For mobile */
	@media only screen and (max-width: 767px) {
	  
		width: 100%;
	  
	}
	

	

}
