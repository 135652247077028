.feeds-section{
.MuiIconButton-edgeEnd {
    position: absolute;
    left: 0;
}
.MuiExpansionPanelSummary-root {
    padding: 0px 16px 0px 38px;
}
.makeStyles-heading-2 {
    font-size: 1.1rem;
    font-weight: 500!important;
}

.MuiExpansionPanelDetails-root{ display: inherit;}

.MuiExpansionPanel-rounded:first-child{ 
    border-radius: 0px;}
.MuiExpansionPanel-rounded:last-child {
    box-shadow: none!important;
    border-bottom: 0px solid #e4e6ee;
    border-radius: 0px;
}


.MuiExpansionPanelSummary-expandIcon {
    transform: rotate(-90deg)!important;
}
 .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg)!important;
}

}


