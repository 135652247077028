//Deals Main

.deal-top {
  padding: 0px;
  margin: 0px 0px 0px auto;
  list-style: none;
  text-align: right;
  li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    .symbol-label {
      background-color: #fff;
      border-radius: 7px;
      padding: 5px;
      height: 37px;
      width: 37px;
      display: flex;
      margin-left: 5px;
      align-items: center;
      vertical-align: middle;
      @include media-breakpoint-down(md) {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.profilepannel {
  .form-control {
    height: 35px !important;
  }

  select {
    &.form-control {
      width: 100%;
    }
  }
  .input-group-append {
    .input-group-text {
      padding: 0.35rem 0.5rem;
      @include media-breakpoint-down(md) {
        padding: 0.35rem 0.2rem;
      }
    }
  }
}

// Deals Tabs Settings

/*.deals-tabs {
  .nav-tabs {
    border-bottom: 2px solid #e4e6ef;
    .nav-item {
      margin-bottom: -2px;
      border-radius: 0px !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      border-radius: 0px !important;
      &::hover {
        border-bottom: 2px solid #ff6b25!important;
        font-weight: 500;
      }
      &::focus {
        border-bottom: 2px solid #ff6b25!important;
        font-weight: 500;
      }
      &.active {
        border-bottom: 2px solid #ff6b25!important;
        font-weight: 500;
      }

      &.nav-link {
        @include media-breakpoint-down(lg) {
          padding: 0.75rem 0.3rem;
        }
        
      }
    }
  }
}*/

.deals-tabs {
  .nav-tabs {
    border: 0px;
    .nav-link {
      position: relative; border:0px solid transparent;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
     
      
      img {
        filter: invert(50%) sepia(51%) saturate(50%) hue-rotate(346deg)
          brightness(104%) contrast(97%);
      }
      &:hover {
        color:$primary-light; border:0px solid transparent!important; border-radius: 0px;
        
        img{ filter: brightness(0) saturate(100%) invert(60%) sepia(69%) saturate(2230%) hue-rotate(344deg) brightness(98%) contrast(94%);}
      }
      &.active {
        border-radius: 4px !important;
        border: 1px solid $primary-light !important;
        background-color: $primary-light;
        color: #fff;
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(67deg) brightness(93%) contrast(111%);
        }
      }
    }
  }
}

// Deals Feed Tab
.card {
  &.deals-feed {
    background-color: transparent !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    .card-header {
      padding: 0px !important;
    }
    .card-body {
      padding: 0px !important;
    }
  }
}

// Deals Note

.fieldset-area {
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 1px solid #e4e6ef !important;
    legend {
      font-size: 12px !important;
      width: inherit !important;
      margin-left: 15px;
      color: #7e8299;
      font-weight: 500;
    }

    .comment-box {
      h5 {
        font-size: 13px;
        color: #414141;
      }
      p {
        font-size: 12px;
        color: #6d6d6d;
      }
    }
  }
}

.deal-note-icon {
  margin-top: 10px;
  .symbol-label {
    background-color: #f3f6f9 !important;
  }
}

.input-group-append {
  .input-group-text {
    background-color: transparent !important;
    padding: 0.35rem 1rem;
    svg {
      filter: invert(15%) sepia(51%) saturate(15%) hue-rotate(346deg)
        brightness(104%) contrast(97%);
    }
  }
}

.w-20 {
  width: 116px;
}

.link-butn {
  .btn {
    padding: 0px !important;
  }
}
.duedate {
  width: 120px;
  text-align: right;
  @include media-breakpoint-down(lg) {
    text-align: left;
  }
}
.tasktype {
  width: 200px;
}
.taskname {
  width: 430px;
}

.edit-task {
  @include media-breakpoint-down(lg) {
    flex-direction: column !important;
  }
  @include media-breakpoint-down(md) {
    flex-direction: inherit !important;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column !important;
  }
}

// Deals Email
.dealsemail {
  @include media-breakpoint-down(lg) {
    flex-direction: column !important;
  }

  .username {
    width: 190px;
    font-weight: 500;
    font-size: 14px;
  }
  .emaildate {
    width: 240px;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: left;
      margin-top: 10px;
    }
  }
}

// WebActivity

.webact-count {
  max-width: 500px;
  margin: 0px auto;
}



.kanban-board{
  float: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem !important;
  background-color: #F3F6F9;
  border-radius: 0.42rem;

 .kanban-board-header {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;

    header {
      font-size: 16px;
      padding: 15px;
    }

    .kanban-title-board {
      font-size: 1.2rem;
      font-weight: 500;
      color: #181C32;
  }
}
.kanban-drag {
  min-height: 200px;
  padding: 2px 10px;

  ul{ padding:0px; margin: 0px; list-style: none;
  li{padding:0px 0px 0px 40px; margin: 0px 0px 10px 0px; list-style: none; position: relative; font-size: 16px;
  figure{ position: absolute; left:0px;}
  }
  
  }
}

}


.deals-grid .col-md-3 {
  flex: 0 0 20%;
  max-width: 20%;
  @include media-breakpoint-down(xl) {
    flex: 0 0 33.33%;
    max-width: 33.33%;

  }
  @include media-breakpoint-down(lg) {
    flex: 0 0 50%;
    max-width: 50%;

  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
    max-width: 100%;

  }

}

.deals-grid .card.card-custom > .card-body {
  padding: 2rem 1rem;
}

.deals-grid h2, .deals-grid .h2 {
  font-size: 1.10rem;
}
.deals-grid ul li { font-size: 14px!important;}

.deals-grid ul li figure { top: -4px!important;}